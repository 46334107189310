import React, { useEffect } from 'react';
import { PDFDocument } from 'pdf-lib';
import { MAX_MEGAS_ENCRYPT_SIZE, MAX_FILE_UPLOAD_ENCRYPT,
	ACCEPT_FILES_VALIDATE, MAX_FILE_UPLOAD, ACCEPT_FILES_ENCRYPT, API_DROPBOX_APP_KEY, MAX_MEGAS_FILES_SIZE, MAX_MEGAS_FILE_SIZE, ACCEPT_FILES } from '../../redux/types/ApiConstants';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import DropboxChooser from 'react-dropbox-chooser';
import iconDropbox from '../../assets/img/DropboxIcon.png';
import { kbToMb } from '../../helpers/FilesFunction';
 
const GetFileDropBox = (props) => {

	const {
		isDecrypt,
		isEncrypt,setStatusModalDropbox,
		maxSize,
		isP7s,
		setStatusModalCloudSelect,
		files,
		setFiles,
		setLoading,
		sizeFiles,
	} = props;

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setStatusModalDropbox(true);
	});

	const downloadFile = (file) => {
		return new Promise(function (resolve) {
			let request = new XMLHttpRequest();
			request.open("GET", file.link, true);
			request.responseType = 'blob';
			let lastEditedUtc = Date().toLocaleString();
			request.onload = function () {
				if (this.status >= 200 && this.status < 300) {
					let blobFile = request.response;
					blobFile.lastModified = lastEditedUtc;
					blobFile.lastModifiedDate = lastEditedUtc;
					blobFile.name = file.name;
					blobFile.url = file.link;
					blobFile.sizeBytes = file.bytes;
					resolve(blobFile);
				} else {
					enqueueSnackbar(
						`No fue posible descargar el documento`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
						},
					);
					resolve(null);
				}
			};
			request.onerror = function () {
				enqueueSnackbar(
					`No fue posible descargar el documento`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
					},
				);
				resolve(null);
			};
			request.send();
		});
	}

	const readFile = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = error => reject(error);
		  reader.readAsArrayBuffer(file);
		});
	}

	const cutName = (name) => {
		if(name != null && name.length > (window.innerWidth > 991 ? 70 : 10)) return (name.substring(0, (window.innerWidth > 991 ? 70 : 10)).concat('...'))
		else return name
	};
	
	async function getPageNumber (pFiles)  {
		var validFiles = [];
		for await (const pFile of pFiles) {
			if (pFile.numPages === undefined) {		
				if('.' + pFile.name.split('.').pop() == '.pdf'){
					try {
						const arrayBuffer = await readFile(pFile);
						const pdf = await PDFDocument.load(arrayBuffer);
						pFile.numPages = pdf.getPages().length;
						validFiles.push(pFile);
					} catch (error) {
						enqueueSnackbar(
							`El archivo ${cutName(pFile.name)} está protegido y no es posible hacerle cambios`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							},
						)
					}
				} else {
					pFile.numPages = 0;
					validFiles.push(pFile);
				}
			}
		}
		if(validFiles.length > 0){
			enqueueSnackbar('Archivos agregados exitosamente.', { 
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		await setFiles([...files, ...validFiles]);
	}

	const onSuccessP7s = async(data) => {
		setLoading(true);
		setStatusModalCloudSelect(false);
		if (data.length > 1) {
			enqueueSnackbar(
				`Solo se puede verificar un documento.`,
				{
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
				},
			);
			setLoading(false);
		} else {
			let downloadFiles = [];
			for (let index = 0; index < data.length; index++) {
				const file = await downloadFile(data[index]);
				if (file != null) {
					downloadFiles.push(file);
				}
			}
			let listFiles = [];
			for (let index = 0; index < downloadFiles.length; index++) {
				const file = await verificateFileP7s(downloadFiles[index]);
				if (file != null) {
					listFiles.push(file);
				}
			}
			setLoading(false);
			if (listFiles.length > 0) {
				setFiles(listFiles);
				enqueueSnackbar('Archivos agregados exitosamente.', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                });
			}
		}
	}
	
	const verificateFileP7s = (file) => {
		return new Promise(function (resolve) {
			var extension = '.' + file.name.split('.').pop();
			var isAccept = ACCEPT_FILES_VALIDATE.find(element => element == extension);
			if (!isAccept) {
				enqueueSnackbar(
					`El archivo ${cutName(file.name)} no es permitido, los tipos de archivos permitidos son: ${ACCEPT_FILES_VALIDATE.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				);
				resolve(null);
			} else {
				var fileSizeMb = Math.round(file.size / 1024);
				var maxSizeMb = Math.round(maxSize / 1024);
				if (fileSizeMb > maxSizeMb) {
					enqueueSnackbar(
						`El tamaño del archivo ${cutName(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(maxSize)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
        	                anchorOrigin: {
        	                    vertical: 'top',
        	                    horizontal: 'right',
        	                },
						},
					);
					resolve(null);
				} else {
					resolve(file);
				}
			}
		});
	}

	const verificateFile = (file) => {
		return new Promise(function (resolve) {
			let dropedSize = sizeFiles;
			var maxFilesSizeMb;
			if(isEncrypt) maxFilesSizeMb = Math.round((MAX_MEGAS_ENCRYPT_SIZE * 1000000) / 1000);
			else maxFilesSizeMb = Math.round((MAX_MEGAS_FILES_SIZE * 1000000) / 1000);
			let sizekiloByte = Math.round(file.sizeBytes / 1024);
			dropedSize += sizekiloByte;
			if (dropedSize > maxFilesSizeMb) {
				enqueueSnackbar(
					`El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(isEncrypt ? MAX_MEGAS_ENCRYPT_SIZE * 1000000 : MAX_MEGAS_FILES_SIZE * 1000000)}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
					},
				);
				resolve(null);
			} else {
				var extension = '.' + file.name.split('.').pop();
				var isAccept;
				if(isEncrypt) isAccept = ACCEPT_FILES_ENCRYPT.find(element => element.toLowerCase() == extension.toLowerCase());
				else if(isDecrypt) isAccept = ['.zip'].find(element => element == extension);
				else isAccept = ACCEPT_FILES.find(element => element.toLowerCase() == extension.toLowerCase());
				if (!isAccept) {
					var exten = isEncrypt ? ACCEPT_FILES_ENCRYPT : isDecrypt ? ['.zip'] : ACCEPT_FILES;
					enqueueSnackbar(
						`El archivo ${cutName(file.name)} no es permitido, los tipos de archivos permitidos son: ${exten.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					resolve(null);
				} else if(file.name.length > 200){
					enqueueSnackbar(
						`El archivo ${cutName(file.name)} tiene un nombre muy largo`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					resolve(null);
				} else {
					var fileSizeMb = Math.round(file.sizeBytes / 1024);
					var maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1000);
					if (fileSizeMb > maxSizeMb) {
						enqueueSnackbar(
							`El tamaño del archivo ${cutName(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(MAX_MEGAS_FILE_SIZE * 1000000)}`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							},
						);
						resolve(null);
					} else {
						var results = files.filter(function (doc) { return doc.name == file.name; });
						var existe = (results.length > 0) ? results[0] : null;
						if (existe != null) {
							enqueueSnackbar(
								`El archivo ${cutName(file.name)} se encuentra actualmente agregado`,
								{
									style: { whiteSpace: 'pre-line' },
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								},
							);
							resolve(null);
						} else {
							resolve(file);
						}
					}
				}
			}
		});
	}

	const cancelButtonHandler = () => {
		setStatusModalCloudSelect(false);
	}

	const onSuccess = async (data) => {
		setLoading(true);
		setStatusModalCloudSelect(false);
		const fileNumber = files.length + data.length;
		var numFiles;
		if(isEncrypt) numFiles = MAX_FILE_UPLOAD_ENCRYPT;
		else if(isDecrypt) numFiles = 1;
		else numFiles = MAX_FILE_UPLOAD;
		if (fileNumber > numFiles) {
			enqueueSnackbar(`La cantidad de archivos supera el máximo permitido de ${numFiles} archivos.`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
					autoHideDuration: 5000,
				},
			);
			setLoading(false);
		} else {
			let downloadFiles = [];
			for (let index = 0; index < data.length; index++) {
				const file = await downloadFile(data[index]);
				if (file != null) {
					downloadFiles.push(file);
				}
			}
			let listFiles = [];
			for (let index = 0; index < downloadFiles.length; index++) {
				const file = await verificateFile(downloadFiles[index]);
				if (file != null) {
					listFiles.push(file);
				}
			}
			setLoading(false);
			if (listFiles.length > 0) {
				await getPageNumber(listFiles);
			}
			cancelButtonHandler();
		}
	}

	return (
		<DropboxChooser
			appKey={API_DROPBOX_APP_KEY}
			success={isP7s ? files => onSuccessP7s(files) : files => onSuccess(files)}
			cancel={cancelButtonHandler}
			multiselect={true}
			linkType={'direct'}
		>
			<img src={iconDropbox} width='25px' className='buttonPC' /> Dropbox
		</DropboxChooser>
	);

}

const mapStateToProps = (state) => {
	return {
		accessToken: null,
		rows: state.consultSignedDocument.signedDocumentsSelect,
		loading: state.consultSignedDocument.loading,
	}
};

export default connect(mapStateToProps)(GetFileDropBox);