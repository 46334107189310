import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import {MAX_MEGAS_ENCRYPT_SIZE, MAX_FILE_UPLOAD_ENCRYPT,
	ACCEPT_FILES_VALIDATE, MAX_FILE_UPLOAD, ACCEPT_FILES_ENCRYPT, API_ONEDRIVE_APP_CLIENT_ID, API_ONEDRIVE_REDIRECT, MAX_MEGAS_FILES_SIZE, MAX_MEGAS_FILE_SIZE, ACCEPT_FILES } from '../../redux/types/ApiConstants';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { MicrosoftLogin } from '../../assets/MicrosoftLogin';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { kbToMb } from '../../helpers/FilesFunction';
import iconOneDrive from '../../assets/img/OnedriveIcon.png';
import oneDriveAPI from 'onedrive-api';
import { GraphFileBrowser } from '@microsoft/file-browser';
 
const GetFileOneDrive = (props) => {

	const {
		setStatusModalCloudSelect, maxSize, isP7s,
		isDecrypt, isEncrypt, files, setFiles,
		setLoading,
		sizeFiles,
	} = props;

	const { enqueueSnackbar } = useSnackbar();
	const [accessToken, setAccessToken] = useState(null);
	const [isOpen, setOpen] = useState(false);

	const authHandler = (err, data) => {
		if (!err && data) {
			if (!data.authResponseWithAccessToken) {
				setStatusModalCloudSelect(false);
				enqueueSnackbar(
					`Ocurrió un error al autenticarse. \nActive las cookies de terceros e\n intente de nuevo.`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				);
			} else {
				setAccessToken(data.authResponseWithAccessToken.accessToken);
				setOpen(true);
			}
		} else {
			setStatusModalCloudSelect(false);
			enqueueSnackbar(
				`Ocurrió un error al autenticarse. \nActive las cookies de terceros e\n intente de nuevo.`,
				{
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
		}
	};

	const cancelButtonHandler = () => {
		setOpen(false);
		setStatusModalCloudSelect(false);
	}

	const getAuthenticationToken = () => {
		return Promise.resolve(accessToken);
	}

	const downloadFile = (file, accessToken) => {
		return new Promise(function (resolve) {
			let itemID = file.driveItem_203[2];
			let fileStream = oneDriveAPI.items.download({
				accessToken: accessToken,
				itemId: itemID,
			});
			if (itemID != null) {
				let bearerToken = 'Bearer ' + accessToken;
				let urlGet = fileStream.uri.href;
				let request = new XMLHttpRequest();
				request.open('GET', urlGet);
				request.setRequestHeader('Authorization', bearerToken);
				request.responseType = 'blob';
				request.onload = function () {
					if (this.status >= 200 && this.status < 300) {
						let url = fileStream.uri.href.replace('/content', '');
						let xhr = new XMLHttpRequest();
						xhr.open('GET', url, false);
						xhr.setRequestHeader('Authorization', bearerToken);
						xhr.send();
						let blobFile = request.response;
						let jsonResponse = JSON.parse(xhr.response);
						let documentList = jsonResponse;
						blobFile.name = documentList.name;
						blobFile.sizeBytes = blobFile.size;
						resolve(blobFile);
					} else {
						enqueueSnackbar(
							`No fue posible descargar el documento`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
							},
						);
						resolve(null);
					}
				};
				request.onerror = function () {
					enqueueSnackbar(
						`No fue posible descargar el documento`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
						},
					);
					resolve(null);
				};
				request.send();
			}
		});
	}

	const readFile = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = error => reject(error);
		  reader.readAsArrayBuffer(file);
		});
	}

	async function getPageNumber (pFiles)  {
		var validFiles = [];
		for await (const pFile of pFiles) {
			if (pFile.numPages === undefined) {		
				if('.' + pFile.name.split('.').pop() == '.pdf'){
					try {
						const arrayBuffer = await readFile(pFile);
						const pdf = await PDFDocument.load(arrayBuffer);
						pFile.numPages = pdf.getPages().length;
						validFiles.push(pFile);
					} catch (error) {
						enqueueSnackbar(
							`El archivo ${cutName(pFile.name)} está protegido y no es posible hacerle cambios`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							},
						)
					}
				} else {
					pFile.numPages = 0;
					validFiles.push(pFile);
				}
			}
		}
		if(validFiles.length > 0){
			enqueueSnackbar('Archivos agregados exitosamente.', { 
				variant: 'success', 
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		await setFiles([...files, ...validFiles]);
	}
	
	const cutName = (name) => {
		if(name != null && name.length > (window.innerWidth > 991 ? 70 : 10)) return (name.substring(0, (window.innerWidth > 991 ? 70 : 10)).concat('...'))
		else return name
	};

	const onSuccessP7s = async(data) => {
		setLoading(true);
		setStatusModalCloudSelect(false);
		if (data.length > 1) {
			enqueueSnackbar(
				`Solo se puede verificar un documento.`,
				{
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
				},
			);
			setLoading(false);
		} else {
			let downloadFiles = [];
			for (let index = 0; index < data.length; index++) {
				const file = await downloadFile(data[index], accessToken);
				if (file != null) {
					downloadFiles.push(file);
				}
			}
			let listFiles = [];
			for (let index = 0; index < downloadFiles.length; index++) {
				const file = await verificateFileP7s(downloadFiles[index]);
				if (file != null) {
					listFiles.push(file);
				}
			}
			setLoading(false);
			if (listFiles.length > 0) {
				setFiles(listFiles);
				enqueueSnackbar('Archivos agregados exitosamente.', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                });
			}
		}
	}
	
	const verificateFileP7s = (file) => {
		return new Promise(function (resolve) {
			var extension = '.' + file.name.split('.').pop();
			var isAccept = ACCEPT_FILES_VALIDATE.find(element => element == extension);
			if (!isAccept) {
				enqueueSnackbar(
					`El archivo ${cutName(file.name)} no es permitido, los tipos de archivos permitidos son: ${ACCEPT_FILES_VALIDATE.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				);
				resolve(null);
			} else {
				var fileSizeMb = Math.round(file.size / 1024);
				var maxSizeMb = Math.round(maxSize / 1024);
				if (fileSizeMb > maxSizeMb) {
					enqueueSnackbar(
						`El tamaño del archivo ${cutName(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(maxSize)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
        	                anchorOrigin: {
        	                    vertical: 'top',
        	                    horizontal: 'right',
        	                },
						},
					);
					resolve(null);
				} else {
					resolve(file);
				}
			}
		});
	}

	const verificateFile = (file) => {
		return new Promise(function (resolve) {
			let dropedSize = sizeFiles;
			let maxFilesSizeMb = Math.round((MAX_MEGAS_FILES_SIZE * 1000000) / 1000);
			let sizekiloByte = Math.round(file.sizeBytes / 1024);
			dropedSize += sizekiloByte;
			if (dropedSize > maxFilesSizeMb) {
				enqueueSnackbar(
					`El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(MAX_MEGAS_FILES_SIZE * 1000000)}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
					},
				);
				resolve(null);
			} else {
				var extension = '.' + file.name.split('.').pop();
				var isAccept;
				if(isEncrypt) isAccept = ACCEPT_FILES_ENCRYPT.find(element => element.toLowerCase() == extension.toLowerCase());
				else if(isDecrypt) isAccept = ['.zip'].find(element => element == extension);
				else isAccept = ACCEPT_FILES.find(element => element.toLowerCase() == extension.toLowerCase());
				if (!isAccept) {
					var exten = isEncrypt ? ACCEPT_FILES_ENCRYPT : isDecrypt ? ['.zip'] : ACCEPT_FILES;
					enqueueSnackbar(
						`El archivo ${cutName(file.name)} no es permitido, los tipos de archivos permitidos son: ${exten.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					resolve(null);
				} else if(file.name.length > 200){
					enqueueSnackbar(
						`El archivo ${cutName(file.name)} tiene un nombre muy largo`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					resolve(null);
				} else {
					var fileSizeMb = Math.round(file.sizeBytes / 1024);
					var maxSizeMb;
					if(isEncrypt) maxSizeMb = Math.round((MAX_MEGAS_ENCRYPT_SIZE * 1000000) / 1000);
					else maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1000);
					if (fileSizeMb > maxSizeMb) {
						enqueueSnackbar(
							`El tamaño del archivo ${cutName(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(isEncrypt ? MAX_MEGAS_ENCRYPT_SIZE * 1000000 : MAX_MEGAS_FILES_SIZE * 1000000)}`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							},
						);
						resolve(null);
					} else {
						var results = files.filter(function (doc) { return doc.name == file.name; });
						var existe = (results.length > 0) ? results[0] : null;
						if (existe != null) {
							enqueueSnackbar(
								`El archivo ${cutName(file.name)} se encuentra actualmente agregado`,
								{
									style: { whiteSpace: 'pre-line' },
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								},
							);
							resolve(null);
						} else {
							resolve(file);
						}
					}
				}
			}
		});
	}

	const onSuccess = async (data) => {
		setLoading(true);
		setStatusModalCloudSelect(false);
		const fileNumber = files.length + data.length;
		var numFiles;
		if(isEncrypt) numFiles = MAX_FILE_UPLOAD_ENCRYPT;
		else if(isDecrypt) numFiles = 1;
		else numFiles = MAX_FILE_UPLOAD;
		if (fileNumber > numFiles) {
			enqueueSnackbar(`La cantidad de archivos supera el máximo permitido de ${numFiles} archivos.`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
			setLoading(false);
		} else {
			let downloadFiles = [];
			for (let index = 0; index < data.length; index++) {
				const file = await downloadFile(data[index], accessToken);
				if (file != null) {
					downloadFiles.push(file);
				}
			}
			let listFiles = [];
			for (let index = 0; index < downloadFiles.length; index++) {
				const file = await verificateFile(downloadFiles[index]);
				if (file != null) {
					listFiles.push(file);
				}
			}
			setLoading(false);
			if (listFiles.length > 0) {
				await getPageNumber(listFiles);
			}
		}
	}

	return (
		<div>
			<MicrosoftLogin
				clientId={API_ONEDRIVE_APP_CLIENT_ID}
				authCallback={authHandler}
				redirectUri={API_ONEDRIVE_REDIRECT}
				graphScopes={['Files.ReadWrite.All', 'Application.ReadWrite.All']}
				className={'buttonOneDriveGet'}
				auto={false}
			>
				<img src={iconOneDrive} width='25px' className='buttonPC' />One Drive
			</MicrosoftLogin>
			<Dialog
				fullWidth={true}
				maxWidth='md'
				disableBackdropClick
				open={isOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<DialogContent>
					<GraphFileBrowser
						getAuthenticationToken={getAuthenticationToken}
						onSuccess={isP7s ? onSuccessP7s : onSuccess}
						onCancel={cancelButtonHandler}
						selectionMode="single"
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		accessToken: null,
		rows: state.consultSignedDocument.signedDocumentsSelect,
		loading: state.consultSignedDocument.loading,
	}
};

export default connect(mapStateToProps)(GetFileOneDrive);