import guion from '../../assets/img/guion.png';
import React, { useState } from 'react';
import { Modal, Grid, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
	getBase64OfSelectedFile,
	getBase64OfSelectedFileArr,
	downloadFromS3,
	deleteFile,
} from '../../redux/actions/ConsultSignedDocumentsAction';
import imgGoogleDrive from '../../assets/img/driveIcon.png';
import imgGoogleDriveHover from '../../assets/img/driveIcon.png';
import imgOneDrive from '../../assets/img/oneDrive.png';
import imgDropbox from '../../assets/img/DropboxIcon.png';
import imgDropboxHover from '../../assets/img/DropboxIconHover.png';
import imgDownloadIcon from '../../assets/img/descargarIcon.png';
import imgDumpIcon from '../../assets/img/eliminarIcon.png';
import IconButton from '@material-ui/core/IconButton';
import GoogleDriveExplorer from './ExplorerGoogleDrive';
import DropboxExplorer from './ExplorerDropBox';
import OneDriveExplorer from './ExplorerOneDriveModal';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonsSign from '../../components/ButtonsSign';
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	title: {
		flex: '1 1 100%',
		textAlign: 'left',
	},
	buttonMargin: {
		margin: '2px',
	},
	image: {
		width: '25px',
		height: '25px',
	},
	modalpopUpshare: {
		visibility: 'hidden',
	},
	iconDownload: {
		borderRadius: '5px',
		backgroundImage: `url(${imgDownloadIcon}) `,
		backgroundPosition: 'center',
		width: '28px !important',
		height: '28px !important',
		marginLeft: '20px !important',
	},
	buttonDelete: {
		backgroundImage: `url(${imgDumpIcon}) `,
		backgroundPosition: 'center',
		borderRadius: '5px',
		width: '28px !important',
		height: '28px !important',
		marginLeft: '20px !important',
	},
	buttonGoogleDrive: {
		margin: '2px',
		backgroundImage: `url(${imgGoogleDrive}) `,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		width: '32px !important',
		height: '32px !important',
		'&:hover': {
			backgroundImage: `url(${imgGoogleDriveHover})`,
		},
	},
	buttonOneDrive: {
		backgroundImage: `url(${imgOneDrive}) `,
		backgroundPosition: 'center',
		borderRadius: '5px',
		width: '28px !important',
		height: '28px !important',
		marginLeft: '20px',
		marginRight: '20px',
	},
	buttonDropbox: {
		margin: '2px',
		backgroundImage: `url(${imgDropbox}) `,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		width: '32px !important',
		height: '32px !important',
		'&:hover': {
			backgroundImage: `url(${imgDropboxHover})`,
		},
	},
}));

const useStylesCheckOrange = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 0,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 2px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIcon: {
		backgroundColor: 'white',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage: `url(${guion})`,
			backgroundRepeat: 'no-repeat',
			marginTop: '40%',
			marginLeft: '18%',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
	iconRes: {
		border: '1px solid #9D9D9C !important',
		borderRadius: 2,
		width: 18,
		height: 18,
		boxShadow: 'inset 0 0 0 1px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIconRes: {
		backgroundColor: 'white',
		'&:before': {
			display: 'block',
			width: 18,
			height: 18,
			backgroundImage: `url(${guion})`,
			backgroundRepeat: 'no-repeat',
			marginTop: '40%',
			marginLeft: '20%',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
});

function StyledCheckbox(props) {
	const classes = useStylesCheckOrange();
	return (
		<Checkbox
			className={classes.root}
			disableRipple
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			indeterminateIcon={
				<span className={clsx(classes.iconRes, classes.checkedIconRes)} />
			}
			{...props}
		/>
	);
}

const EnhancedTableToolbar = props => {
	const [statusModalGoogleDrive, setStatusModalGoogleDrive] = useState(false);
	const [statusModalOneDrive, setStatusModalOneDrive] = useState(false);
	const [tokenOneDrive, setTokenOneDrive] = useState(null);
	const [files, setFiles] = useState([]);
	const classes = useToolbarStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const isSmallScreen = useMediaQuery('(max-width:600px)');

	const {
		numSelected,
		selected,
		downloadFromS3,
		rows,
		handleSelectAllClick,
		setSelected,
		isP7,
		refresh,
		responsiveMode,
		isFilterIsNull,
		tabSelected,
		setTabSelected,
		disabledBtnDelete,
		setOpenedRowIndex,
		user,
		url
	} = props;

	
	const handleClickDownload = e => {
		let selectFiles = [];
		selected.forEach(file => {
			const docs = rows.find(row => row.id === file);

			if (docs) {
				selectFiles.push(docs);
			} else {
				const foundMatch = rows
					.filter(row => row.documentCircuito !== null)
					.flatMap(row => row.documentCircuito)
					.find(row => row.id === file);

				selectFiles.push(foundMatch);
			}
		});

		downloadFromS3(selectFiles, enqueueSnackbar);
		selectFiles.length === 10 && handleSelectAllClick(e);
		setSelected([]);
	};

	const deselectAllFiles = () => {
		setSelected([]);
	};

	const handleClickDelete = async e => {
		props.setModalDeleteFiles(true);
	};

	const handleClickOneDrive = e => {
		setStatusModalOneDrive(true);
	};

	if (loading) return <Loading />;


	return (
		<>
			<Toolbar
				className={clsx(classes.root, {
					[classes.highlight]: numSelected > 0,
				})}
			>
				{numSelected > 0 && tabSelected === 0 && (
					<Typography
						className={classes.title}
						variant='h6'
						id='tableTitle'
						component='div'
					>
						<Grid
							container
							className={classes.grid}
							direction='row'
							alignItems='center'
						>
							<>
								<StyledCheckbox
									className={'checkButtonDocumentSigned'}
									responsiveMode={responsiveMode}
									indeterminate={
										props.numSelected > 0 && props.numSelected < props.rowCount
									}
									checked={
										props.rowCount > 0 && props.numSelected >= props.rowCount
									}
									onChange={handleSelectAllClick}
								/>

								<ButtonsSign
									style={'style'}
									responsiveMode={responsiveMode}
									handleClickDownload={handleClickDownload}
									handleClickDelete={handleClickDelete}
									files={files}
									setFiles={setFiles}
									isP7={isP7}
									filesSize={0}
									rows={rows}
									selected={selected}
									refresh={refresh}
									setOpenedRowIndex={setOpenedRowIndex}
									tabSelected={tabSelected}
								/>
							</>

							{responsiveMode != true && (
								<>
									<div className='verticalLine1'></div>
									<Grid item>
										<IconButton
											tooltip='true'
											className={classes.iconDownload}
											data-title='Descargar'
											onClick={handleClickDownload}
										></IconButton>
									</Grid>
									<Grid item>
										<IconButton
											tooltip='true'
											className={classes.buttonDelete}
											data-title='Eliminar'
											onClick={handleClickDelete}
											disabled={disabledBtnDelete}
										></IconButton>
									</Grid>
									<div className='verticalLine'></div>
									<Grid item data-title='Guardar en Google Drive'>
										<GoogleDriveExplorer
											open={statusModalGoogleDrive}
											disableBackdropClick
											onClose={() => setStatusModalGoogleDrive(false)}
											setStatusModalGoogleDrive={setStatusModalGoogleDrive}
											selected={selected}
											rows1={rows}
											setSelected={setSelected}
										></GoogleDriveExplorer>
									</Grid>
									<Grid item style={{ marginLeft: '17px' }}>
										<DropboxExplorer
											selected={selected}
											setSelected={setSelected}
											rows={rows}
										/>
									</Grid>
									<Grid item>
										<IconButton
											tooltip='true'
											data-title='Guardar en OneDrive'
											className={classes.buttonOneDrive}
											onClick={handleClickOneDrive}
										></IconButton>
									</Grid>
								</>
							)}
							<Modal
								open={statusModalOneDrive}
								disableBackdropClick
								onClose={() => {
									setStatusModalOneDrive(0);
								}}
								className={classes.modalpopUpshare}
							>
								<div>
									<OneDriveExplorer
										tokenOneDrive={tokenOneDrive}
										setTokenOneDrive={setTokenOneDrive}
										setStatusModalOneDrive={setStatusModalOneDrive}
										selected={selected}
										rows1={rows}
										setSelected={setSelected}
									/>
								</div>
							</Modal>
						</Grid>
					</Typography>
				)}{' '}
				{numSelected > 0 && tabSelected === 1 && (
					<>
						{!isFilterIsNull && tabSelected === 0 && (
							<StyledCheckbox
								className={'checkButtonDocumentSigned'}
								responsiveMode={responsiveMode}
								indeterminate={
									props.numSelected > 0 && props.numSelected < props.rowCount
								}
								checked={
									props.rowCount > 0 && props.numSelected === props.rowCount
								}
								onChange={handleSelectAllClick}
							/>
						)}
					</>
				)}
				{tabSelected === 2 && (
					<Typography
						className={classes.title}
						variant='h6'
						id='tableTitle'
						component='div'
					>
						<Grid
							container
							direction={isSmallScreen ? 'column-reverse' : 'row'} // Cambia a columna si es pantalla pequeña
							alignItems='start'
							spacing={2}
						>
							{/* El Checkbox siempre se muestra */}
							<Grid
								item
								xs={isSmallScreen ? 12 : 'auto'}
								className={classes.fullWidth}
							>
								<StyledCheckbox
									className={'checkButtonDocumentSigned'}
									indeterminate={
										props.numSelected > 0 && props.numSelected < props.rowCount
									}
									checked={props.numSelected > 0}
									onChange={props.handleSelectAllClick}
								/>
							</Grid>

							{/* Botón Sign solo se muestra si numSelected > 0 */}
							{props.numSelected > 0 && (
								<Grid
									item
									xs={isSmallScreen ? 12 : "auto"}
									className={classes.fullWidth}
								>
									<ButtonsSign
										style={'style'}
										responsiveMode={props.responsiveMode}
										handleClickDownload={props.handleClickDownload}
										handleClickDelete={props.handleClickDelete}
										files={files}
										setFiles={setFiles}
										isP7={props.isP7}
										rows={props.rows}
										selected={props.selected}
										onSuccessfulSign={props.deselectAllFiles}
										refresh={props.refresh}
										setOpenedRowIndex={props.setOpenedRowIndex}
										tabSelected={props.tabSelected}
										setTabSelected={props.setTabSelected}
										user={props.user}
										url={props.url}
									/>
								</Grid>
							)}
						</Grid>
					</Typography>
				)}
			</Toolbar>
		</>
	);
};

	

const mapStateToProps = state => ({
	 rows: state.consultSignedDocument.documentSign,
	rowsSelected: state.consultSignedDocument.signedDocumentsSelect,
	listBase64: state.consultSignedDocument.signedDocumentsSelect,
});

const mapDispatchToProps = {
	obtenerBase64: getBase64OfSelectedFile,
	obtenerBase64Arr: getBase64OfSelectedFileArr,
	downloadFromS3: downloadFromS3,
	deleteFile: deleteFile,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EnhancedTableToolbar);
